<template>
  <v-container>
    <HeaderDialog
      id="create-user"
      :title="$ml.get('create_users')"
      :show="value"
      width="550"
      @close="$emit('input', false)"
      :loading="loading"
      :disabled="loading"
      :disabledAction="!valid || loading"
      @action="confirm"
    >
      <template v-slot:body>
        <v-form ref="form" v-model="valid" @submit.prevent="">
          <v-row justify="center" class="ma-0 pa-0 flex-column">
            <v-col align="center">
              <v-text-field
                v-model="name"
                :label="$ml.get('name')"
                :rules="[rules.minSize]"
                :disabled="loading"
              />
            </v-col>
            <v-col align="center">
              <v-row align="baseline" class="ma-0 pa-0">
                <v-col class="pa-0">
                  <v-text-field
                    v-model="userName"
                    :label="$ml.get('email')"
                    :rules="[rules.required]"
                    :disabled="loading"
                    @focus="autoCompleteUserName"
                  />
                </v-col>
                <v-col class="pa-0">
                  <v-select
                    v-model="domain"
                    :items="domainsList"
                    :label="$ml.get('domain')"
                    :rules="[rules.required]"
                    :disabled="loading"
                    prepend-icon="mdi-at"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col align="center">
              <v-row justify="center" align="center">
                <v-col align="center">
                  <v-switch
                    v-model="askChangePass"
                    :label="$ml.get('ask_reset_password_first_access')"
                    :disabled="loading"
                  />
                </v-col>
                <v-col align="center">
                  <v-switch
                    v-model="useDefaultPass"
                    :label="$ml.get('use_default_password')"
                    :disabled="loading"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col align="center" class="flex-nowrap" v-if="!useDefaultPass">
              <v-row class="ma-0 pa-0 flex-nowrap" align="baseline">
                <v-text-field
                  v-model="password"
                  :label="$ml.get('password')"
                  :rules="companyRules"
                  :disabled="loading"
                  :append-icon="hidePass ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="hidePass ? 'password' : 'text'"
                  @click:append="hidePass = !hidePass"
                  class="mr-2"
                />
                <Tooltip
                  color="primary"
                  :text="$ml.get('control_only_password')"
                  :disabled="loading"
                  icon="mdi-information"
                />
              </v-row>
            </v-col>
            <v-col align="center" v-if="!useDefaultPass && password">
              <v-text-field
                v-model="confirmPassword"
                :label="$ml.get('confirm_password')"
                :rules="companyRules"
                :disabled="loading"
                :append-icon="hideConfirmPass ? 'mdi-eye-off' : 'mdi-eye'"
                :type="hideConfirmPass ? 'password' : 'text'"
                @click:append="hideConfirmPass = !hideConfirmPass"
              />
            </v-col>
          </v-row>
        </v-form>
      </template>
    </HeaderDialog>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Tooltip from "@/components/base/Tooltip";
import { removeAccents } from "@/helpers/utils";

export default {
  name: "CreateUsersDialog",
  components: {
    Tooltip,
  },
  props: {
    // Implementação do v-model, igual o v-dialog faz.
    // Esse "value" é o valor do v-model, e que significa o v-model do v-dialog
    value: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      valid: null,
      name: null,
      userName: null,
      domain: null,
      askChangePass: null,
      useDefaultPass: true,
      password: null,
      confirmPassword: null,
      hidePass: true,
      hideConfirmPass: true,
      min: 5,
      rules: {
        equalPasswords: (value) =>
          value === this.confirmPassword ||
          this.$ml.get("passwords_do_not_match"),

        min_len: (v) =>
          (!!v && v.length >= this.minSize) ||
          `${this.$ml.get("min_len_label")} ${this.minSize}`,

        has_upper: (v) =>
          /[A-Z]/.test(v) || this.$ml.get("must_have_a_upper_letter"),

        has_lower: (v) =>
          /[a-z]/.test(v) || this.$ml.get("must_have_a_lower_letter"),

        has_number: (v) => /\d/.test(v) || this.$ml.get("must_have_a_number"),
        //eslint-disable-next-line
        has_symbol: (v) =>
          /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(v) ||
          this.$ml.get("must_have_a_symbol"),

        required: (v) => !!v || this.$ml.get("required_field"),

        minSize: (v) =>
          (!!v && v.length >= this.min) ||
          `${this.$ml.get("min_len_label")} ${this.min}`,
      },
      loading: false,
      returnCodes: {
        USER_ALREADY_EXISTS: {
          message: "error_user_already_exists",
          color: "error",
        },
        CONTROL_USER_CREATED: {
          message: "warning_control_user_created",
          color: "warning",
        },
        MICROSOFT_USER_CREATED: {
          message: "success_ms_user_created",
          color: "success",
        },
      },
    };
  },
  computed: {
    ...mapGetters(["company", "domainsList", "passwordStrength"]),

    minSize() {
      if (this.passwordStrength.min_len) {
        return this.passwordStrength.min_len;
      }
      return this.min;
    },

    companyRules() {
      if (!this.useDefaultPass && this.passwordStrength)
        return Object.entries(this.passwordStrength).reduce(
          (total, [key, value]) =>
            value ? total.concat([this.rules[key]]) : total,
          []
        );
      return [];
    },

    defaultFormValues() {
      return {
        name: null,
        userName: null,
        domain: null,
        askChangePass: null,
        useDefaultPass: true,
        password: null,
        confirmPassword: null,
      };
    },
  },
  watch: {
    company() {
      if (this.domainsList.length && !this.domain) {
        this.domain = this.domainsList[0];
      }
    },
  },
  methods: {
    ...mapActions(["setSnackbarDefault", "setUsers"]),
    autoCompleteUserName() {
      if (!this.userName && this.name) {
        this.userName = removeAccents(this.name)
          .replace(/\s/gm, "")
          .toLowerCase();
      }
    },
    async confirm() {
      this.valid = this.$refs.form.validate();
      if (this.valid) {
        await this.createMsUser();
        this.$emit("input", false);
      }
    },
    clearForm() {
      Object.entries(this.defaultFormValues).forEach(
        ([key, value]) => (this[key] = value)
      );
    },
    async createMsUser() {
      this.loading = true;

      const url = "/users/create";
      const payload = {
        name: this.name.trim(),
        email: `${this.userName.trim()}@${this.domain.trim()}`,
        force_change_pass: !!this.askChangePass,
        password: this.useDefaultPass ? "" : this.password.trim(),
      };

      try {
        const { data } = await this.$axios.put(url, { users: [payload] });
        const codes = [];
        this.setUsers(
          Object.values(data).map((result) => {
            if (result.code) {
              codes.push(result.code);
            }
            return result.user;
          })
        );
        if (codes.length > 0)
          this.setSnackbarDefault({
            ...this.returnCodes[codes[0]],
            show: true,
          });
        this.clearForm();
      } catch (error) {
        console.error(error);
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.code in this.returnCodes
        ) {
          this.setSnackbarDefault({
            ...this.returnCodes[error.response.data.code],
            show: true,
          });
        } else {
          this.setSnackbarDefault({
            message: "service_unavailable",
            color: "error",
            show: true,
          });
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
