<template>
  <v-dialog v-model="showDialog" width="250" hide-overlay>
    <v-card elevation="2" tile>
      <v-card-title class="primary white--text mx-0">
        {{ $ml.get(titleKey) }}
        <v-spacer />
        <v-btn icon @click="showDialog = false" dark right>
          <v-icon v-text="'mdi-close'" />
        </v-btn>
      </v-card-title>

      <v-card-text class="mt-4">
        <v-combobox
          v-model="result"
          persistent-placeholder
          :label="$ml.get('match')"
          :items="itemsToShow"
          multiple
          return-object
          small-chips
        ></v-combobox>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          :disabled="!hasAFilter"
          @click="filterApply"
        >
          {{ $ml.get("apply") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    id: { type: String, required: true },
    titleKey: { type: String, required: true },
    show: { type: Boolean, required: true },
  },
  data() {
    return { result: [] };
  },
  computed: {
    ...mapGetters(["groups"]),

    itemsToShow() {
      return this.groups.map((group) => {
        return {
          value: group.key,
          text: group.name,
        };
      });
    },
    showDialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close_" + this.id);
      },
    },
    hasAFilter() {
      return this.result && this.result !== "";
    },
  },
  methods: {
    filterApply() {
      let filter = {
        key: this.titleKey,
        result: this.result,
      };
      this.$emit("filterDone", filter);
      this.showDialog = false;
    },
  },
};
</script>
