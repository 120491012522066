<template>
  <v-list :style="`width:${width}px`" class="pa-0">
    <div v-for="item in menu" :key="item.key">
      <v-menu
        :ref="`${item.key}`"
        transition="scroll-x-reverse-transition"
        :close-on-content-click="false"
        v-if="item.submenu"
        offset-x
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="grey darken-3"
            class="text-subtitle-2 text-none text-left py-2"
            tile
            block
            text
            v-bind="attrs"
            v-on="on"
            x-large
          >
            {{ $ml.get(item.title) }}
            <v-spacer />
            <v-icon v-if="!!item.submenu" v-text="'mdi-chevron-right'" />
          </v-btn>
        </template>
        <v-card class="pa-0" elevation="4" max-height="600" tile>
          <v-app-bar dense :color="color" class="pa-0" :dark="dark" flat>
            <v-btn small icon class="mr-2" @click="closeSubMenu(item.key)">
              <v-icon v-text="'mdi-chevron-left'" />
            </v-btn>
            <span v-if="item.submenu">{{ $ml.get(item.submenu.title) }}</span>
          </v-app-bar>

          <v-list tile v-if="item.subList">
            <v-list-item
              @click="
                selectSubItem(
                  item.subList.filter,
                  item.subList.title,
                  item.subList.key
                )
              "
            >
              <v-list-item-title :disabled="item.subList.disabled">
                {{ $ml.get(item.subList.title) }}
              </v-list-item-title>
            </v-list-item>
          </v-list>

          <v-divider />

          <v-card tile v-if="item.key === 'uos'">
            <v-treeview
              class="cursor-pointer"
              :items="item.submenu.options"
              return-object
              :active.sync="active"
              item-key="path"
              item-text="name"
              activatable
              open-all
            ></v-treeview>
          </v-card>

          <v-list v-else-if="item.submenu" tile>
            <v-list-item
              v-for="subItem in getVisibleOptions(item.submenu.options)"
              :key="subItem.key"
              :to="hasTo(subItem)"
              :href="hasHref(subItem)"
              @click="selectSubItem(item.filter, subItem.title, subItem.key)"
            >
              <v-list-item-title>
                {{
                  subItem.no_translate ? subItem.title : $ml.get(subItem.title)
                }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
      <v-btn
        v-else
        color="grey darken-3"
        class="text-subtitle-2 text-none text-left py-2"
        tile
        block
        text
        x-large
        @click="selectSubItem(item.filter, item.title, item.key)"
      >
        {{ $ml.get(item.title) }}
        <v-spacer />
      </v-btn>
    </div>
  </v-list>
</template>
<script>
export default {
  /*  ESTRUTURA DE OPTIONS
    [
      {
        title: "",
        key: "",
        show: true,
        action: "",
        submenu: {
          title: "",
          options: [
            {
              title: "",
              to: "",
              href:"",
              key: "",
              show: true,
            }
          ]
        }
      }
    ]
  */
  name: "FilterMenu",
  props: {
    options: { type: Array, default: () => [] },
    color: { type: String, default: "grey lighten-4" },
    action: { type: String, default: null },
    width: { type: [String, Number], default: "270" },
    dark: { type: Boolean, default: false },
  },
  data() {
    return {
      active: [],
    };
  },
  computed: {
    menu() {
      return this.options.filter((item) => item.show === true);
    },
  },
  methods: {
    hasHref(item) {
      return item.href ? item.href : "";
    },
    hasTo(item) {
      return item.to ? item.to : "";
    },
    closeSubMenu(key) {
      this.$refs[key][0].save();
    },
    getVisibleOptions(itens) {
      return itens.filter((item) => item.show);
    },
    selectSubItem(item, filter, key) {
      if (this.action) {
        let payload = {
          filter: filter,
          type: item,
          key,
        };
        this.$emit(this.action, payload);
      }
    },
  },
  watch: {
    active(selectedOrgUnit) {
      if (selectedOrgUnit.length) {
        const { name, path } = selectedOrgUnit[0];
        this.selectSubItem("ou", name, path);
      }
    },
  },
};
</script>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
