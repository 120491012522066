<template>
  <v-container fluid class="pa-0">
    <Banner
      :value="showVacationBanner"
      :title="$ml.get('deny_user_access_in_vacation')"
      :description="$ml.get('deny_user_access_in_vacation_description')"
      :dimiss-text="$ml.get('dismiss')"
      :image="bannerImage"
      image-width="400"
      @dimiss="hideVacationBanner()"
    >
      <template v-slot:customActionButton>
        <v-play-video-button
          :smallBtn="true"
          color="info"
          :title="$ml.get('vacation_mode_video_title')"
          idYoutubeVideo="0IoVMBt3L8Q"
        />
      </template>
    </Banner>
    <v-card
      outlined
      max-width="1400"
      :class="`${$vuetify.breakpoint.mobile ? 'mx-4' : 'mx-auto'}`"
      flat
    >
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="allUsers"
        sort-by="name"
        :custom-sort="customSort"
        class="elevation-0"
        :search="search"
        show-select
        item-key="key"
        :single-select="false"
        checkbox-color="accent"
        :loading="loadingUsers"
        @toggle-select-all="getPageSelection"
        :class="`${$vuetify.theme.dark ? 'background' : ''}`"
        :style="`${
          $vuetify.theme.dark
            ? 'border-bottom: 1px solid #424242 !important'
            : ''
        }`"
        :items-per-page="itemsPerPage"
        :footer-props="{ itemsPerPageOptions: itemsOptions }"
        :height="tableHeight"
        fixed-header
        mobile-breakpoint="0"
      >
        <template v-slot:top>
          <v-row id="users_header" class="ma-0 pa-0 px-4" align="center">
            <v-card-title
              :class="`
              px-2
              ${$vuetify.breakpoint.mdAndUp ? 'mx-2 ml-1 mr-0' : 'ma-0'}
              word-break`"
            >
              {{ $ml.get("users") }}
            </v-card-title>
            <v-divider
              vertical
              class="my-6 mx-1"
              v-if="$vuetify.breakpoint.width >= 1032"
            />
            <v-card-subtitle
              v-if="$vuetify.breakpoint.width >= 1032 && !isFiltering"
              :class="`
                  word-break
                  ${
                    $vuetify.theme.dark
                      ? 'grey--text text--lighten-5'
                      : 'grey--text text--darken-2'
                  }
                  text-body-1
                  mx-2 mr-1 ml-0
                `"
            >
              {{
                hasSelectedUsers
                  ? `${selectedUsersNumber} ${$ml.get("users_selected")}`
                  : $ml.get("show_all_users")
              }}
            </v-card-subtitle>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-tooltip bottom>
                  <template #activator="{ on: onTooltip }">
                    <span
                      v-on="!hasSelectedUsers && onTooltip"
                      :style="!$vuetify.breakpoint.smAndUp ? 'order: 1;' : ''"
                    >
                      <v-btn
                        text
                        color="accent"
                        :class="`mx-2 my-1 text-subtitle-2 text-none font-weight-medium mr-5 ${btnAlert}`"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!hasSelectedUsers"
                      >
                        {{ $ml.get("more") }}
                        <v-icon v-text="'mdi-menu-down'" class="ml-1" small />
                      </v-btn>
                    </span>
                  </template>
                  <span>{{ $ml.get("more_description") }}</span>
                </v-tooltip>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="(item, i) in moreOptions"
                  :key="i"
                  @click="execute(item.action)"
                >
                  <v-list-item-title
                    :class="`${
                      item.action === 'request_users_logout' ? btnAlert : ''
                    }`"
                  >
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu offset-y max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="grey"
                  rounded
                  text
                  class="mx-2 my-1 px-4 py-2 text-body-2 filter-outlined"
                  v-bind="attrs"
                  v-on="on"
                  :block="$vuetify.breakpoint.xs"
                  :style="$vuetify.breakpoint.width < 760 ? 'order: 1;' : ''"
                >
                  <v-icon v-text="'mdi-plus'" class="my-0 mr-2" />
                  {{ $ml.get("add_filter") }}
                </v-btn>
              </template>
              <FilterMenu
                :options="filterOptions"
                action="selectFilter"
                @selectFilter="setFilters"
              />
            </v-menu>
            <v-spacer v-if="$vuetify.breakpoint.width >= 760" />

            <!-- CRIAR USUÁRIOS MICROSOFT -->
            <div
              :style="!$vuetify.breakpoint.smAndUp ? 'display: contents;' : ''"
            >
              <v-btn
                v-if="isMicrosoft"
                large
                elevation="0"
                color="accent"
                :text="$vuetify.theme.dark"
                :outlined="!$vuetify.theme.dark"
                @click="showCreateUsersDialog = !showCreateUsersDialog"
                :block="$vuetify.breakpoint.xs"
                :class="`mx-2 my-1 text-none text-body-1 font-weight-medium`"
                :disabled="loadingSync"
              >
                <v-icon v-text="'mdi-pencil'" left size="20" />
                <span> {{ $ml.get("create_users") }}</span>
              </v-btn>
              <v-btn
                large
                elevation="0"
                color="accent"
                :text="$vuetify.theme.dark"
                :outlined="$vuetify.theme.dark"
                @click="syncUsers"
                :block="$vuetify.breakpoint.xs"
                :class="`mx-2 my-1 text-none text-body-1 font-weight-medium`"
                :disabled="loadingSync"
              >
                <v-icon
                  v-text="'mdi-sync'"
                  left
                  size="20"
                  :class="`${loadingSync ? 'icon-spinner' : ''}`"
                />
                <span> {{ $ml.get("sync") }}</span>
              </v-btn>
            </div>
          </v-row>

          <Alert
            class="ma-2"
            type="warning"
            v-if="totalUsersWithoutGroup"
            dense
            @action="filterUsersWithoutGroup"
            :action-text="$ml.get('show_users_without_group')"
            :btnWidth="$vuetify.breakpoint.smAndDown ? '100' : '25'"
            :alignBtnTextStart="$vuetify.breakpoint.smAndDown ? true : false"
          >
            {{ $ml.get("without_group_alert") }},
            {{ $ml.get("without_group_alert2") }}.
          </Alert>

          <v-divider v-if="isFiltering" />

          <!-- FILTROS APLICADOS ATUAL -->

          <v-row v-if="isFiltering" class="align-center ma-0 px-4 py-2">
            <span class="font-weight-medium mr-2">
              {{ $ml.get("selected_filters") }}
            </span>
            <v-chip
              v-for="(filter, index) in filters"
              :key="index"
              outlined
              small
              class="mr-1 my-1 grey--text text--darken-1 font-weight-medium px-2"
            >
              {{
                [
                  "without_group",
                  "is_on_vacation",
                  "active",
                  "suspended",
                ].includes(filter.key)
                  ? $ml.get(filter.filter)
                  : filter.filter
              }}
              <v-btn
                v-if="!hasSelectedUsers"
                x-small
                icon
                class="ml-1"
                @click="removeFilter(filter)"
              >
                <v-icon v-text="'mdi-window-close'" size="16" />
              </v-btn>
            </v-chip>
          </v-row>

          <v-divider />
          <v-row
            v-if="allSelectedAreInTheSamePage || allSelected"
            :class="`${
              $vuetify.theme.dark ? 'grey darken-2' : 'grey lighten-3'
            } d-flex align-center justify-center px-2 my-0 py-2 ma-0 text-body-2`"
            :style="$vuetify.breakpoint.mdAndUp ? 'height: 40px' : ''"
          >
            <!-- TEXTO QUE APARECE QUANDO TODOS OS ITEMS DA PÁGINA ESTÃO SELECIONADOS -->
            <div
              v-if="allSelectedAreInTheSamePage && !allSelected"
              class="d-flex align-center text-wrap"
            >
              {{ $ml.get("all_of") }}
              <span class="font-weight-medium mx-1">{{
                selectedUsersNumber
              }}</span>
              {{ $ml.get("users").toLowerCase() }}
              {{ $ml.get("page_selected") }}
              <v-btn
                text
                x-small
                color="info darken-2"
                :block="$vuetify.breakpoint.sm"
                class="text-none font-weight-medium text-body-2 px-1 py-0 my-0"
                @click="selected = allUsers.slice()"
              >
                {{ $ml.get("select_all_of") }}
                {{ $ml.get("users").toLowerCase() }}
              </v-btn>
            </div>
            <!-- TEXTO QUE APARECE QUANDO TODOS OS ITENS DA TABELA ESTÃO SELECIONADOS -->
            <div v-if="allSelected" class="d-flex align-center text-wrap">
              {{ $ml.get("all_of") }}
              <span class="font-weight-medium mx-1">{{
                selectedUsersNumber
              }}</span>
              {{ $ml.get("users").toLowerCase() }}
              {{ $ml.get("table_selected") }}
              <v-btn
                text
                x-small
                color="info darken-2"
                class="text-none font-weight-medium text-body-2 px-1 py-0 my-0"
                @click="selected = []"
              >
                {{ $ml.get("clear_selection") }}
              </v-btn>
            </div>
          </v-row>
          <v-divider v-if="allSelectedAreInTheSamePage || allSelected" />
          <v-row style="max-height: 72px" class="px-0">
            <v-text-field
              v-model="search"
              :placeholder="$ml.get('search_users')"
              class="elevation-0 mx-1 mt-4 mb-0 transparent"
              background-color="transparent"
              filled
              dense
              rounded
              height="25"
              full-width
              persistent-placeholder
            >
              <template v-slot:prepend-inner>
                <v-icon v-text="'mdi-magnify'" class="my-0 mr-9" left />
              </template>
            </v-text-field>
          </v-row>
          <v-divider />
        </template>

        <template v-slot:loading> {{ $ml.get("loading") }} </template>
        <template v-slot:no-data> {{ $ml.get("no_data") }} </template>
        <template v-slot:no-results> {{ $ml.get("no_results") }} </template>

        <!-- INÍCIO: HEADERS -->
        <template v-slot:[`header.name`]="{ header }">
          {{ $ml.get(header.value) }}
        </template>
        <template v-slot:[`header.email`]="{ header }">
          {{ $ml.get(header.value) }}
        </template>
        <template v-slot:[`header.last_login`]="{ header }">
          {{ $ml.get(header.value) }}
        </template>
        <template v-slot:[`header.groups`]="{ header }">
          {{ $ml.get(header.value) }}
        </template>
        <template v-slot:[`header.is_active`]="{}">
          {{ $ml.get("status") }}
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                size="16"
                v-text="'mdi-help-circle-outline'"
              />
            </template>
            <span>{{ $ml.get("is_active_user_label") }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.is_admin`]="{}">
          {{ $ml.get("function") }}
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                size="16"
                v-text="'mdi-help-circle-outline'"
              />
            </template>
            <span>{{ $ml.get("is_google_admin_label") }}</span>
          </v-tooltip>
        </template>
        <!-- É ADMIN -->
        <template v-slot:[`item.name`]="{ item }">
          <div class="d-flex align-center">
            <v-avatar size="28" class="mr-2">
              <img :src="item.photo || userDefaultImage" :alt="item.username" />
            </v-avatar>
            <span
              class="blue--text text--darken-2 link"
              @click="$router.push({ name: 'User', params: { id: item.key } })"
              >{{ item.name || item.username }}</span
            >
            <Tooltip
              v-if="item.is_on_vacation || scheduledVacation(item)"
              :color="scheduledVacation(item) ? 'secondary' : 'accent'"
              :text="`
                ${$ml.get('vacation_period')} ${$ml
                .get('from')
                .toLowerCase()} ${formatDateUTC(
                item.start_vacation_date
              )} ${$ml.get('until')} ${formatDateUTC(item.end_vacation_date)}`"
            />
          </div>
        </template>
        <template v-slot:[`item.last_login`]="{ item }">
          {{ item.last_login ? formatDateAndHour(item.last_login) : "-" }}
        </template>
        <template v-slot:[`item.is_admin`]="{ item }">
          {{ item.is_admin ? $ml.get("admin") : $ml.get("member") }}
        </template>
        <template v-slot:[`item.is_active`]="{ item }">
          <v-row class="ma-0" no-gutters>
            <v-col xl="3" lg="4" sm="5">
              <v-tooltip top v-if="item.oauth == 'control' && isMicrosoft">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="warning"
                    size="20"
                    v-text="'mdi-help-circle-outline'"
                  />
                </template>
                <span>{{ $ml.get("not_syncronized_ms_label") }}</span>
              </v-tooltip>
              <v-icon
                v-else
                :color="item.is_active ? 'success' : 'error'"
                size="20"
                v-text="
                  item.is_active
                    ? 'mdi-check-circle-outline'
                    : 'mdi-close-circle-outline'
                "
              />
            </v-col>
            <v-col v-if="!$vuetify.breakpoint.md">
              {{
                item.oauth == "control"
                  ? $ml.get("not_syncronized")
                  : item.is_active
                  ? $ml.get("active")
                  : $ml.get("suspended")
              }}
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.groups`]="{ item }">
          {{ item.groups.join(", ") || $ml.get("no_group") }}
        </template>
        <template v-slot:[`item.more`]="{ item }">
          <v-menu offset-y left :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                right
                color="grey"
                icon
                v-bind="attrs"
                v-on="on"
                :disabled="hasSelectedUsers"
                @click="assigUser(item)"
              >
                <v-icon v-text="'mdi-dots-vertical'" />
              </v-btn>
            </template>
            <v-list dense>
              <v-overlay :value="loadingUsers">
                <v-progress-circular indeterminate></v-progress-circular>
              </v-overlay>
              <v-list-item
                v-for="(itemMenu, index) in userOptions"
                :key="index"
                :disabled="item.disabled"
                @click="executeUserAction(itemMenu.action, item)"
              >
                <v-list-item-title class="font-weight-medium">
                  {{ itemMenu.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <FilterDialog
      :show="filtersDialog.filter_group"
      id="filter_group"
      titleKey="groups"
      @close_filter_group="filtersDialog.filter_group = false"
      @filterDone="applyFilter"
    />

    <ChangeUserPassword
      v-if="editingUser"
      :show="changePasswordDialog"
      :user="curentUserToEdit"
      :user-key="curentUserToEdit.key"
      :emails="isChangingUsersPassword ? selectedUsersEmail : []"
      @close="closePasswordChange"
    />

    <!-- DIALOG DE ADICIONAR O USUÁRIO A UM GRUPO -->
    <HeaderDialog
      id="edit-group-participants"
      :title="
        hasSelectedUser ? selected[0].email : $ml.get('add_selected_to_group')
      "
      :subtitle="hasSelectedUser ? $ml.get('add_user_to_group') : ''"
      :show="showAddGroupParitcipantsDialog"
      :loading="loadingSelectedGroup"
      :disabledAction="!selectedGroup"
      width="600"
      @close="closeGroupDialog"
      @action="addParticipants"
    >
      <template v-slot:body>
        <v-row>
          <v-col>
            <v-autocomplete
              v-model="selectedGroup"
              :items="availableGroups"
              item-text="name"
              item-value="key"
              :label="`${$ml.get('choose')} ${$ml.get('group').toLowerCase()}`"
            />
          </v-col>
        </v-row>
      </template>
    </HeaderDialog>

    <!-- DIALOG DE REMOVER USUÁRIOS DE UM GRUPO -->
    <HeaderDialog
      id="delete_group_participants"
      :title="
        hasSelectedUser
          ? selected[0].email
          : $ml.get('remove_selected_from_this_group')
      "
      :subtitle="hasSelectedUser ? $ml.get('remove_user_from_a_group') : ''"
      :show="showRemoveGroupParitcipantsDialog"
      :loading="loadingSelectedGroup"
      :disabledAction="!selectedGroup"
      width="600"
      @close="closeGroupDialog"
      @action="confirmationDeleteParticipantsDialog = true"
      closeText="cancel"
      :actionText="$ml.get('remove')"
    >
      <template v-slot:body>
        <v-row>
          <v-col>
            <v-autocomplete
              v-model="selectedGroup"
              :items="availableGroupsToRemoveUsers"
              item-text="name"
              item-value="key"
              :label="`${$ml.get('choose')} ${$ml.get('group').toLowerCase()}`"
            />
          </v-col>
        </v-row>
      </template>
    </HeaderDialog>

    <!-- DIALOG DE CONFIRMAÇÃO -->
    <HeaderDialog
      id="show_delete_confirmation"
      :title="
        hasSelectedUser
          ? selected[0].email
          : $ml.get('remove_selected_from_this_group')
      "
      :subtitle="hasSelectedUser ? $ml.get('remove_user_from_a_group') : ''"
      :show="confirmationDeleteParticipantsDialog"
      :loading="loadingSelectedGroup"
      :disabledAction="!selectedGroup"
      width="620"
      closeText="cancel"
      @close="closeGroupDialog"
      @action="deleteGroupParticipants"
    >
      <template v-slot:body>
        <p class="word-break">
          {{
            hasSelectedUser
              ? $ml.get("confirm_delete_user_from_group_label")
              : $ml.get("confirm_delete_users_from_group_label")
          }}
          "<span class="font-weight-bold"> {{ selectedGroupName }} </span>"?
        </p>
      </template>
    </HeaderDialog>

    <SingleAccessDialog
      v-if="editingUser"
      :show="dialog.single_access"
      :user="editingUser"
      @close="dialog.single_access = false"
      @update="updateUserInListUsers"
    />

    <Vacation
      :show="vacationDialog"
      @close="closeVacationDialog"
      :emails="selectedUsersEmail"
      :user="editingUser"
    />

    <ConfirmationDialog
      :target="actionName"
      :show="showConfirmDialog"
      :loading="loading"
      @close="showConfirmDialog = false"
      @ok="okConfirmDialog"
      action-text=""
      :reversible="false"
    >
    </ConfirmationDialog>

    <!-- DIALOG DE CRIAÇÃO DE USUÁRIO -->
    <CreateUsersDialog v-model="showCreateUsersDialog" />
  </v-container>
</template>
<script>
import Banner from "@/components/base/Banner";
import FilterDialog from "@/components/base/FilterDialog";
import Tooltip from "@/components/base/Tooltip";
import ConfirmationDialog from "@/components/general/ConfirmationDialog.vue";
import ChangeUserPassword from "@/components/users/ChangeUserPassword.vue";
import SingleAccessDialog from "@/components/users/SingleAccessDialog.vue";
import Vacation from "@/components/users/Vacation";
import FilterMenu from "@/components/base/FilterMenu";
import CreateUsersDialog from "@/components/users/CreateUsersDialog.vue";
import * as moment from "moment";
import {
  formatDateAndHour,
  formatDateUTC,
  paging,
  scheduledVacation,
  tableHeight,
} from "@/helpers/utils";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "Users",
  components: {
    FilterDialog,
    ChangeUserPassword,
    Vacation,
    Tooltip,
    ConfirmationDialog,
    SingleAccessDialog,
    Banner,
    FilterMenu,
    CreateUsersDialog,
  },
  data() {
    return {
      loading: false,
      confirmationDeleteParticipantsDialog: false,
      vacationDialog: false,
      showRemoveGroupParitcipantsDialog: false,
      showSnackbarAllSelected: false,
      showAddGroupParitcipantsDialog: false,
      selectedGroup: null,
      selected: [],
      filters: [],
      currentFilters: {
        groups: [],
      },
      filtersDialog: {
        filter_group: false,
      },
      search: "",
      dialog: {
        request_user_password_change: false,
        single_access: false,
      },
      editingUser: null,
      passwordFieldMask: true,
      allItemsInThePage: [],
      isChangingUsersPassword: false,
      itemsPerPage: paging.perPage,
      itemsOptions: paging.All,
      tableHeight,
      showConfirmDialog: false,
      actionDialog: "",
      actionName: "",
      userInfoAction: "",
      bannerImage: require("@/assets/images/AtribuirModoFerias.gif"),
      showVacationBanner: false,
      showCreateUsersDialog: false,
    };
  },

  mounted() {
    if (localStorage.getItem("nextPageAction") === "vacation") {
      this.showVacationBanner = true;
    }
  },

  async created() {
    if (this.$route.params.applyNoGroupFilter) {
      this.filterUsersWithoutGroup;
    }
  },

  watch: {
    "dialog.request_user_password_change"() {
      if (!this.dialog.request_user_password_change) {
        this.editingUser = null;
        this.showPasswordMask = true;
      }
    },
    allSelected() {
      if (this.allSelected && !this.isFiltering) {
        this.setSnackbarDefault({
          message: "all_users_selected",
          color: "info",
        });
      }
    },
    "$route.path"() {
      this.setEditUserMode({ key: "user_shifts", value: false });
    },
  },
  computed: {
    ...mapGetters([
      "users",
      "groups",
      "loadingSync",
      "loadingUsers",
      "userDefaultImage",
      "dialogChangePasswordUser",
      "totalUsersWithoutGroup",
      "loadingSelectedGroup",
      "isMicrosoft",
    ]),

    filterOptions() {
      return [
        {
          title: "groups",
          key: "groups",
          show: true,
          submenu: {
            title: "groups",
            options: this.groupsToFilter,
          },
        },
        {
          title: "status",
          key: "status",
          show: true,
          submenu: {
            title: "status",
            options: [
              {
                title: "active_users",
                key: "active",
                show: true,
              },
              {
                title: "inactive_users",
                key: "suspended",
                show: true,
              },
            ],
          },
        },
        {
          title: "is_on_vacation",
          key: "is_on_vacation",
          show: true,
        },
        {
          title: "without_group",
          key: "without_group",
          show: true,
        },
      ];
    },

    groupsToFilter() {
      return this.groups.map((group) => {
        const filterInfo = {
          title: group.name,
          key: group.key,
          show: true,
          no_translate: true,
        };
        return filterInfo;
      });
    },

    selectedGroupName() {
      if (this.selectedGroup) {
        return (
          this.groups.find((group) => group.key === this.selectedGroup).name ||
          null
        );
      }
      return null;
    },

    curentUserToEdit() {
      return this.editingUser;
    },

    allSelectedAreInTheSamePage() {
      if (this.selected.length > 0) {
        return (
          this.selected.every((item) =>
            this.allItemsInThePage.includes(item)
          ) && this.allItemsInThePage.length === this.selected.length
        );
      } else {
        return false;
      }
    },

    allSelected() {
      return (
        this.allUsers.length === this.selected.length &&
        this.allUsers.length > 0
      );
    },

    changePasswordDialog: {
      get() {
        return this.dialogChangePasswordUser;
      },
      set(new_value) {
        this.setDialogChangePasswordUser(new_value);
      },
    },

    showPasswordMask: {
      get() {
        return this.passwordFieldMask;
      },
      set(new_value) {
        this.passwordFieldMask = new_value;
      },
    },

    moreOptions() {
      return [
        {
          text: this.$ml.get("request_password_change"),
          action: "request_password_change",
          show: true,
        },
        {
          text:
            this.selected.length === 1
              ? this.$ml.get("add_user_to_group")
              : this.$ml.get("add_users_to_shift"),
          action: "add_users_to_shift",
          show: true,
        },
        {
          text: this.$ml.get("remove_selected_from_this_group"),
          action: "remove_selected_from_this_group",
          show: true,
        },
        {
          text: this.$ml.get("change_users_password"),
          action: "change_users_password",
          show: true,
        },
        {
          text: this.$ml.get("request_users_logout"),
          action: "request_users_logout",
          show: true,
        },
        {
          text: this.$ml.get("set_users_vacation"),
          action: "set_users_vacation",
          show: true,
        },
        {
          text: this.$ml.get("remove_users_vacation"),
          action: "remove_users_vacation",
          show: this.someUserOnVacation,
        },
      ].filter((item) => item.show);
    },

    selectedUsersEmail() {
      return this.selected.map((user) => user.email);
    },

    userOptions() {
      return [
        {
          text: this.$ml.get("request_password_change"),
          action: "request_user_password_change",
          show: true,
        },
        {
          text: this.$ml.get("change_user_password"),
          action: "change_user_password",
          show: true,
        },
        /* {
          text: this.$ml.get("edit_groups"),
          action: "",
        }, */
        /* {
          text: this.$ml.get("change_permissions"),
          action: "",
        }, */
        /* {
          text: this.$ml.get("change_password"),
          action: "",
        }, */
        {
          text: this.$ml.get("request_logout"),
          action: "force_logout",
          show: true,
        },
        {
          text: this.$ml.get("add_user_to_group"),
          action: "add_user_to_a_group",
          show: true,
        },
        {
          text: this.$ml.get("remove_user_from_a_group"),
          action: "delete_user_from_a_group",
          show: true,
        },
        {
          text: this.$ml.get("set_user_vacation"),
          action: "set_user_vacation",
          show: true,
        },
        {
          text: this.$ml.get("remove_user_vacation"),
          action: "remove_user_vacation",
          show: this.userOnVacation,
        },
        {
          text: this.$ml.get("grant_single_access"),
          action: "grant_single_access",
          show: !this.userHasSingleAccess,
        },
        {
          text: this.$ml.get("remove_single_access"),
          action: "remove_single_access",
          show: this.userHasSingleAccess,
        },
      ].filter((item) => item.show);
    },

    filterKeys() {
      return this.filters.map(({ key }) => key);
    },

    allUsers() {
      if (this.isFiltering) {
        return this.users.filter((user) => {
          return this.filterKeys.reduce((acc, nextFilter) => {
            return (
              acc &&
              ((nextFilter === "active" && user.is_active) ||
                (nextFilter === "suspended" && !user.is_active) ||
                (nextFilter === "is_on_vacation" &&
                  (user.is_on_vacation ||
                    this.isUserWithVacationPlanned(user))) ||
                (nextFilter === "without_group" && !user.groups.length) ||
                user.rule_group.includes(nextFilter))
            );
          }, true);
        });
      } else {
        return this.users;
      }
    },

    selectedUsersNumber() {
      return this.selected.length;
    },

    hasSelectedUsers() {
      return this.selected.length > 0;
    },

    hasSelectedUser() {
      return this.selected.length === 1;
    },

    isFiltering() {
      return !!this.filters.length;
    },

    headers() {
      return [
        {
          align: "start",
          sortable: true,
          value: "name",
          width: 180,
        },
        {
          value: "email",
        },
        { value: "is_active", width: 160 },
        { value: "last_login", width: 160 },
        { value: "groups" },
        { value: "is_admin", width: 120 },
        { text: "", value: "more", sortable: false, width: 30 },
      ];
    },

    availableGroups() {
      const singleUserIndex = 0;
      return this.hasSelectedUser
        ? this.groupsUserNotIn(this.selected[singleUserIndex])
        : this.groups;
    },

    availableGroupsToRemoveUsers() {
      const singleUserIndex = 0;
      return this.hasSelectedUser
        ? this.groupsUserAreIn(this.selected[singleUserIndex])
        : this.selected.flatMap((user) => this.groupsUserAreIn(user));
    },

    someUserOnVacation() {
      let userOnVacationFound = null;

      if (this.hasSelectedUsers) {
        userOnVacationFound = this.selected.find((user) => {
          return user.is_on_vacation;
        });
      }

      return !!userOnVacationFound;
    },

    userOnVacation() {
      return this.editingUser ? this.editingUser.is_on_vacation : false;
    },

    userHasSingleAccess() {
      return this.editingUser ? this.editingUser.has_single_access : false;
    },

    btnAlert() {
      const path = this.$route.query.action;
      if (path) {
        this.setAllSelected();
        return path === "forceLogout" ? "pulse" : "";
      }
      return "";
    },
  },

  methods: {
    scheduledVacation,
    ...mapActions([
      "syncUsers",
      "forcePasswordChange",
      "forceLogout",
      "forceUsersLogout",
      "editGroupMembers",
      "setSnackbarDefault",
      "removeUserVacation",
      "removeUsersVacationInBatch",
      "updateUsersOnStore",
      "setSnackbarCustomMessage",
    ]),
    ...mapMutations([
      "setDialogChangePasswordUser",
      "setEditUserMode",
      "setLoadingUsers",
      "setSelectedUser",
      "setUsers",
    ]),

    formatDateAndHour,
    formatDateUTC,

    setAllSelected() {
      this.selected = this.allUsers;
    },

    sortByLastLogin(items, emptyItems, sortBy, sortDesc) {
      const sortedItems = items.sort((a, b) => {
        const a_unix = moment(a[sortBy]).unix();
        const b_unix = moment(b[sortBy]).unix();

        if (sortDesc) {
          if (a_unix > b_unix) return 1;
          if (a_unix < b_unix) return -1;
          return 0;
        }
        if (a_unix < b_unix) return 1;
        if (a_unix > b_unix) return -1;

        return 0;
      });

      const result = [...sortedItems, ...emptyItems];
      return result;
    },
    customSort(items, sortBy, sortDesc) {
      sortBy = sortBy[0] || "name";
      sortDesc = sortDesc[0];

      let filteredItems = [...items];
      const emptyItems = items.filter((item) => !item[sortBy]);

      if (sortBy === "last_login") {
        filteredItems = items.filter((item) => item[sortBy]);

        const result = this.sortByLastLogin(
          filteredItems,
          emptyItems,
          sortBy,
          sortDesc
        );

        return result;
      }

      const sortedItems = filteredItems.sort((a, b) => {
        let valueA = a[sortBy];
        let valueB = b[sortBy];

        if (typeof valueA === "string" && typeof valueB === "string") {
          valueA = valueA.toLocaleLowerCase();
          valueB = valueB.toLocaleLowerCase();
        }

        if (sortDesc) {
          if (valueA < valueB) return 1;
          if (valueA > valueB) return -1;
          return 0;
        }
        if (valueA > valueB) return 1;
        if (valueA < valueB) return -1;

        return 0;
      });

      const result = [...sortedItems, ...emptyItems];
      return result;
    },

    isUserWithVacationPlanned(user) {
      const today = new Date();

      const startVacationDate = new Date(user.start_vacation_date);
      const endVacationDate = new Date(user.end_vacation_date);

      if (startVacationDate >= today && endVacationDate >= today) {
        return true;
      }

      return false;
    },

    updateUserInListUsers(userUpdated) {
      this.setSelectedUser({ ...this.selectedUser, ...userUpdated });
      let users = [...this.users];

      const userIndex = users.findIndex((user) => user.key === userUpdated.key);

      users[userIndex] = { ...users[userIndex], ...userUpdated };

      this.setUsers(users);
    },

    hideVacationBanner() {
      localStorage.removeItem("nextPageAction");
      this.showVacationBanner = false;
      this.$router.replace("/users");
    },

    assigUser(user) {
      if (JSON.stringify(this.editingUser) != JSON.stringify(user)) {
        this.editingUser = Object.assign({}, user);
      }
    },

    async addParticipants() {
      let info = {
        key: this.selectedGroup,
        payload: {
          users: this.selected.map((user) => user.email),
        },
      };
      const messageUser = `${this.$ml.get("user")} ${this.$ml.get(
        "success_add_user_to_a_group"
      )}`;

      await this.editGroupMembers(info)
        .then((response) => {
          this.setSnackbarCustomMessage({
            message: this.hasSelectedUser
              ? messageUser
              : `${this.$ml.get("success_add_users_to_group")}`,
            color: "success",
          });
          this.updateUsersOnStore(response);
        })
        .catch((error) => {
          this.setSnackbarDefault({
            message: this.hasSelectedUser
              ? "error_remove_user_from_group"
              : "error_remove_users_from_group",
            color: "error",
          });
          console.error("addGroupsMembersByUsers", error);
        })
        .finally(() => {
          this.closeGroupDialog();
        });
    },

    async deleteGroupParticipants() {
      let info = {
        key: this.selectedGroup,
        payload: {
          users: this.selected.map((user) => user.email),
        },
        is_delete: true,
        custom_response: true,
      };

      await this.editGroupMembers(info)
        .then((response) => {
          this.setSnackbarDefault({
            message: this.hasSelectedUser
              ? "success_remove_user_from_group"
              : "success_remove_users_from_group",
            color: "success",
          });
          this.updateUsersOnStore(response);
        })
        .catch((error) => {
          this.setSnackbarDefault({
            message: this.hasSelectedUser
              ? "error_remove_user_from_group"
              : "error_remove_users_from_group",
            color: "error",
          });
          console.error("deleteGroupsMembersByUsers", error);
        })
        .finally(() => {
          this.closeGroupDialog();
        });
    },

    openGroupDialog(user) {
      if (user) {
        this.selected.push(user);
      }

      this.showAddGroupParitcipantsDialog = true;
    },

    openDeleteGroupParticipantDialog(user) {
      if (user) {
        this.selected.push(user);
      }

      this.showRemoveGroupParitcipantsDialog = true;
    },

    closeGroupDialog() {
      this.selected = [];
      this.showAddGroupParitcipantsDialog = false;
      this.showRemoveGroupParitcipantsDialog = false;
      this.selectedGroup = null;
      this.confirmationDeleteParticipantsDialog = false;
    },

    groupsUserNotIn(user_info) {
      if (user_info) {
        return this.groups.filter((group) => {
          return !user_info.rule_group.includes(group.key);
        });
      } else {
        return this.groups;
      }
    },

    groupsUserAreIn(user_info) {
      if (user_info) {
        return this.groups.filter((group) => {
          return user_info.rule_group.includes(group.key);
        });
      } else {
        return this.groups;
      }
    },

    getPageSelection(event) {
      if (event.value) {
        this.allItemsInThePage = event.items;
      } else {
        this.allItemsInThePage = [];
        this.selected = [];
      }
    },

    setFilters(filter_option) {
      if (!this.isFiltering) {
        this.filters.push(filter_option);
      } else {
        if (!this.filterKeys.includes(filter_option.key)) {
          if (
            filter_option.key === "active" &&
            this.filterKeys.includes("suspended")
          ) {
            const index = this.filters.findIndex(
              (item) => item.key === "suspended"
            );
            this.filters.splice(index, 1);
          }
          if (
            filter_option.key === "suspended" &&
            this.filterKeys.includes("active")
          ) {
            const index = this.filters.findIndex(
              (item) => item.key === "active"
            );
            this.filters.splice(index, 1);
          }
          this.filters.push(filter_option);
        }
      }
    },

    removeFilter(filter_option) {
      const index = this.filters.findIndex((filter) => {
        return filter.key === filter_option.key;
      });
      this.filters.splice(index, 1);
    },

    filterUsersWithoutGroup() {
      this.setFilters({
        title: "without_group",
        key: "without_group",
        filter: "without_group",
      });
    },

    applyFilter(filter) {
      this.currentFilters[filter.key].push(filter.result);
    },

    async okConfirmDialog() {
      if (this.actionDialog === "request_password_change") {
        await this.forcePasswordChange(this.selectedUsersEmail).finally(() => {
          this.showConfirmDialog = false;
          this.resetUsersSelection();
        });
      } else if (this.actionDialog === "request_user_password_change") {
        this.loading = true;
        await this.forcePasswordChange([this.userInfoAction.email]).finally(
          () => {
            this.loading = false;
            this.showConfirmDialog = false;
          }
        );
      } else if (this.actionDialog === "request_users_logout") {
        const payload = {
          users: this.selected.reduce(
            (emails, { is_active, email }) =>
              is_active ? emails.concat(email) : emails,
            []
          ),
        };

        this.forceUsersLogout(payload);
        this.resetUsersSelection();
        if (this.btnAlert) {
          this.$router.replace({});
        }
        this.showConfirmDialog = false;
      } else if (this.actionDialog === "force_logout") {
        this.setLoadingUsers(true);
        this.forceLogout(this.userInfoAction.key).finally(() => {
          this.setLoadingUsers(false);
        });
        this.showConfirmDialog = false;
      }
    },

    //multiple users
    async execute(action) {
      if (action === "request_password_change") {
        this.showConfirmDialog = true;
        this.actionDialog = action;
        this.actionName = this.$ml.get(action);
      } else if (action === "add_users_to_shift") {
        await this.openGroupDialog();
      } else if (action === "remove_selected_from_this_group") {
        this.openDeleteGroupParticipantDialog();
      } else if (action === "change_users_password") {
        this.editingUser = { key: "" };
        this.isChangingUsersPassword = true;
        this.changePasswordDialog = true;
        this.showConfirmDialog = false;
      } else if (action === "request_users_logout") {
        this.actionDialog = action;
        this.showConfirmDialog = true;
        this.actionName = this.$ml.get("request_logout");
      } else if (action === "set_users_vacation") {
        this.vacationDialog = true;
      } else if (action === "remove_users_vacation") {
        this.removeUsersVacationInBatch(this.selectedUsersEmail);
        this.selected = [];
      }
    },

    //single user
    executeUserAction(action, user_info) {
      if (action === "change_user_password") {
        this.changePasswordDialog = true;
      } else if (action === "request_user_password_change") {
        this.showConfirmDialog = true;
        this.actionDialog = action;
        this.actionName = this.$ml.get("request_password_change");
        this.userInfoAction = user_info;
      } else if (action === "force_logout") {
        this.actionDialog = action;
        this.actionName = this.$ml.get("request_logout");
        this.showConfirmDialog = true;
        this.userInfoAction = user_info;
      } else if (action === "add_user_to_a_group") {
        this.openGroupDialog(user_info);
      } else if (action === "delete_user_from_a_group") {
        this.openDeleteGroupParticipantDialog(user_info);
      } else if (action === "set_user_vacation") {
        this.vacationDialog = true;
      } else if (action === "remove_user_vacation") {
        this.removeUserVacation(user_info.key);
      } else if (
        action === "grant_single_access" ||
        action === "remove_single_access"
      ) {
        this.dialog.single_access = true;
      }
    },

    closePasswordChange() {
      this.changePasswordDialog = false;
      this.isChangingUsersPassword = false;
      this.resetUsersSelection();
    },

    resetUsersSelection() {
      this.selected = [];
    },

    closeVacationDialog() {
      this.selected = [];
      this.vacationDialog = false;
    },
  },
};
</script>
